.primary-btn{
  background-color: #337ab7;
  border-color: #2e6da4;
  border: none;
  outline:none;
  color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
 .ripple:hover {
      background: #2e6da4 radial-gradient(circle, transparent 1%,#2e6da4 1%) center/15000%;
}
@media all and (min-width:768px){
  .ripple:hover {
      background: #2e6da4 radial-gradient(circle, transparent 1%,#2e6da4 1%) center/15000%;
  }
}
.ripple:active {
  background-color: #2e6da4;
  background-size: 100%;
  transition: background 0s;
}