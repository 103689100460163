.nav-item a {
    color: rgba(0,0,0,.5);
}
.nav-item a:hover {
    color: rgba(0,0,0,.5);
}

.dropdown-menu {
    left: auto !important;
    right: 0 !important;
}
.dropdown-item:active {
    background-color: #fff !important;
}
.dropdown-item {
    padding: .1rem 1rem !important;
}