.footer-container{
    display: none;
}

.copyright-wrapper {
    display: inline-block;
}

@media all and (min-width:768px) {
    .footer-container{
        display: inline-block;
        box-sizing: content-box;
        background-color: #fff;
        height: 4vh;
        width: 100%;
        position:fixed;
        z-index: 2;
        bottom: 0;
        color:#000;
        text-align: center;
    }
}