@import url(https://fonts.googleapis.com/css?family=Roboto:300);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.route-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;
}
.nav-item a {
    color: rgba(0,0,0,.5);
}
.nav-item a:hover {
    color: rgba(0,0,0,.5);
}

.dropdown-menu {
    left: auto !important;
    right: 0 !important;
}
.dropdown-item:active {
    background-color: #fff !important;
}
.dropdown-item {
    padding: .1rem 1rem !important;
}
.footer-container{
    display: none;
}

.copyright-wrapper {
    display: inline-block;
}

@media all and (min-width:768px) {
    .footer-container{
        display: inline-block;
        box-sizing: content-box;
        background-color: #fff;
        height: 4vh;
        width: 100%;
        position:fixed;
        z-index: 2;
        bottom: 0;
        color:#000;
        text-align: center;
    }
}
.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #5bc0de;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form button:hover,.form button:active,.form button:focus {
  background: #ACACAC;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #A4AEB5;
  text-decoration: none;
}
.form .register-form {
  /* display: none; */
  width: 360px;
  padding: 8%;
  margin: auto;
}
.logincontainer {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before, .container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #EF3B3A;
}
.errortext {
  color: #f00;
  font-size: 12px;
}
.erroralert {
  color: #f00;
  font-size: 12px;
  position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.successtext {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: #4F8A10;
  font-size: 12px;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.apassword{
  color: #39add1;
  text-decoration: none;
  border-bottom: 1px dotted;
  float: right;
}
#wrapper {
  /* padding-left: 250px; */
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

#sidebar-wrapper {
  /* margin-left: -250px;
  left: 250px; */
  width: 250px;
  background: #FFFFFF;
  /* position: fixed; */
  height: 100%;
  overflow-y: auto;
  /* z-index: 1000; */
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

#page-content-wrapper {
  width: 100%;
}

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width:767px) {

    #wrapper {
      padding-left: 0;
    }

    #sidebar-wrapper {
      left: 0;
    }

    #wrapper.active {
      position: relative;
      left: 250px;
    }

    #wrapper.active #sidebar-wrapper {
      left: 250px;
      width: 250px;
      -webkit-transition: all 0.4s ease 0s;
      transition: all 0.4s ease 0s;
    }

}

body {
  /* background: #76b852;  */
  background: #A4AEB5;
  background: -webkit-gradient(linear, right top, left top, from(#A4AEB5), to(#ACACAC));
  background: linear-gradient(to left, #A4AEB5, #ACACAC);
  /* font-family: "Roboto", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;      
}



.topmargin {
  margin-top: 3%;
}
.container {
  /* border-radius: 5px; */
  background-color: #f2f2f2;
  padding: 20px;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

.delete-img {
  width: 10%;
  min-width: 25px;
  max-width: 45px;
}
 .modal-content {
width: 30% !important;
}

.kwRiGp, .gjbLri {
  min-height: 5px !important;
}
li a {
  color: #A4AEB5;
  padding-right: 10px;
}
li a:hover {
  color: rgb(62, 141, 13);
  list-style-type: none;
  text-decoration: none;
}
nav.breadcrumb {
  padding:0 !important;
}
.breadcrumb{
  background-color : transparent !important;
  padding:0 !important;
}
.breadcrumb-item+.breadcrumb-item::before {
  display: none !important;
  margin: 0 0.5em !important;
} 

.hBxfkO .rdt_TableHeadRow .rdt_TableCol {
  font-weight: bold !important;
}
.toastmessages {
  background-color: blanchedalmond !important;
}
.FocusBox {
  background-color:rgba(255, 0, 0, 0.125) !important;
}
.InnerNavbar {
  background: gainsboro;
  padding-top: 10px;
  margin-bottom: 10px;
  
}
.breadcrumb li a {
  color: #000000;
}
.breadcrumb li a:hover {
  color: gray;
}
div#wraps{
  float: left;
  width: 100%;
  position: absolute;
}
div#left-sidebar{
  /* position:absolute; */
  top: 67px;
  left:0;
  /* width:130px; */
  width:14%;
  height:auto;
  background-color: #f8f9fa;
  float:left;
  margin-top: 3%;
  z-index: 0;
 }
 div#contents{
  float:right;
  width: 85%;
  padding-left: 20px;
  padding-right: 20px;
 }
 .nav a {
  color: rgba(0,0,0,.5);
}
.nav a:hover {
  color: #000;
}
.nav-link {
  padding: 0.5rem 0.5rem;
}
.refreshimg {
  min-width: 15px;
  max-width: 25px;
}
.refreshimg:hover {
  cursor: pointer;
}
@media screen{
  body>div#header{
   position:fixed;
  }
  /* body>div#left-sidebar{
   position:fixed;
  } */
 }
/* div#header{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:50px;
 }
div#left-sidebar{
  position:absolute;
  top: 67px;
  left:0;
  width:8%;
  height:auto;
  background-color: #f8f9fa;
  padding-right: 1%;
 }
 div#content{
  height:100% !important;
  overflow:auto;
 }
 @media screen{
  body>div#header{
   position:fixed;
  }
  body>div#left-sidebar{
   position:fixed;
  }
 }
 @media (min-width: 1200px) {
  div#content {
    margin-left: 10%;
}
}
 .nav a {
  color: rgba(0,0,0,.5);
}
.nav a:hover {
  color: #000;
}
.nav-link {
  padding: 0.5rem 0.5rem;
} */

.primary-btn{
  background-color: #337ab7;
  border-color: #2e6da4;
  border: none;
  outline:none;
  color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

/* Ripple effect */
.ripple {
  background-position: center;
  -webkit-transition: background 0.8s;
  transition: background 0.8s;
}
 .ripple:hover {
      background: #2e6da4 radial-gradient(circle, transparent 1%,#2e6da4 1%) center/15000%;
}
@media all and (min-width:768px){
  .ripple:hover {
      background: #2e6da4 radial-gradient(circle, transparent 1%,#2e6da4 1%) center/15000%;
  }
}
.ripple:active {
  background-color: #2e6da4;
  background-size: 100%;
  -webkit-transition: background 0s;
  transition: background 0s;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 3; /* Sit on top */
    padding-top: 25%; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  .close {
    color: white;
    float: right;
    font-size: 2em;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #696D7D;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-header {
    display: inline !important;
    display: initial !important;
    padding: 1% 4%;
    background-color: #CECECE;
    color: #000000;
    font-size: 1.2em;
    min-height: 60px;
  }
  
  .modal-body {
    padding: 1% 4%;
    overflow: hidden;
  }
  
  .modal-footer {
    height: 25px;
    padding: 1% 4%;
    background-color: #CECECE;
    color: #000000;
  }

  .modal-actions {
    float: right;
  }

  .modal-minus-btn, .modal-plus-btn {
    line-height: 235%;
    width: 20%;
    min-height: 25px;
    max-height: 45px;
    min-width: 85px;
    max-width: 130px;   
    margin: 5px;
  }

  .modal-minus-btn {
    background-color: #696D7D;
    color: #ffffff;
  }

  .modal-minus-btn:hover {
    background: #696D7D radial-gradient(circle, transparent 1%,#696D7D 1%) center/15000%;
  }

  @media all and (min-width:768px) {
    .modal-minus-btn:hover {
        background: #a2a3a7 radial-gradient(circle, transparent 1%,#a2a3a7 1%) center/15000%;
    }
    .modal {
        padding-top: 15%;
    }
    .modal-content {
        width: 50%;
    }
  }

  @media all and (min-width:1200px) {
    .modal {
        padding-top: 13%;
    }
    .modal-content {
        width: 40%;
    }
  }

  @media all and (min-width:1500px) {
    .modal {
        padding-top: 10%;
    }
    .modal-content {
        width: 30%;
    }
  }

  .modal-minus-btn:active {
    background-color: #292a2c;
    background-size: 100%;
    -webkit-transition: background 0s;
    transition: background 0s;
  }
  


.input-item-container{
    margin:0;
}
.input-item-container{
    margin:0;
}
