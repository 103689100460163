
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 3; /* Sit on top */
    padding-top: 25%; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  .close {
    color: white;
    float: right;
    font-size: 2em;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #696D7D;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-header {
    display: initial !important;
    padding: 1% 4%;
    background-color: #CECECE;
    color: #000000;
    font-size: 1.2em;
    min-height: 60px;
  }
  
  .modal-body {
    padding: 1% 4%;
    overflow: hidden;
  }
  
  .modal-footer {
    height: 25px;
    padding: 1% 4%;
    background-color: #CECECE;
    color: #000000;
  }

  .modal-actions {
    float: right;
  }

  .modal-minus-btn, .modal-plus-btn {
    line-height: 235%;
    width: 20%;
    min-height: 25px;
    max-height: 45px;
    min-width: 85px;
    max-width: 130px;   
    margin: 5px;
  }

  .modal-minus-btn {
    background-color: #696D7D;
    color: #ffffff;
  }

  .modal-minus-btn:hover {
    background: #696D7D radial-gradient(circle, transparent 1%,#696D7D 1%) center/15000%;
  }

  @media all and (min-width:768px) {
    .modal-minus-btn:hover {
        background: #a2a3a7 radial-gradient(circle, transparent 1%,#a2a3a7 1%) center/15000%;
    }
    .modal {
        padding-top: 15%;
    }
    .modal-content {
        width: 50%;
    }
  }

  @media all and (min-width:1200px) {
    .modal {
        padding-top: 13%;
    }
    .modal-content {
        width: 40%;
    }
  }

  @media all and (min-width:1500px) {
    .modal {
        padding-top: 10%;
    }
    .modal-content {
        width: 30%;
    }
  }

  .modal-minus-btn:active {
    background-color: #292a2c;
    background-size: 100%;
    transition: background 0s;
  }
  